import { Navigate, Route } from "react-router-dom";
import { renderRoutes } from "../utils/helpers/renderRoutes";
import { exportRoutes } from "./export.routes";
import { AppLayout, IndexLayout, SidebarLayout } from "../layouts";
import { appRoutes } from "./app.routes";
import { authRoutes } from "./auth.routes";
import { CABINET_ROUTES, ROUTE, TOOLS_ROUTES } from "../utils/constants";
import { cabinetRoutes } from "./cabinet.routes";
import { toolsRoutes } from "./tools.routes";
import { PerfectMoney } from "../components/payment/perfect-money/perfect-money";

// TODO fix lang, isToolsActive
export const getRoutes = ({lang, isToolsActive, store}) => {

  return (
    <>
      <Route path="/" element={<Navigate to={`/${lang}/`} />} />
      {renderRoutes(exportRoutes, store)}
      <Route element={<IndexLayout />}>
        <Route element={<AppLayout />}>
          {renderRoutes(appRoutes, store)}
          {renderRoutes(authRoutes, store)}
        </Route>
        <Route element={<SidebarLayout routes={CABINET_ROUTES} withExit />}>
          {renderRoutes(cabinetRoutes, store)}
        </Route>
        {isToolsActive ? (
          <Route element={<SidebarLayout routes={TOOLS_ROUTES} withFooter />}>
            {renderRoutes(toolsRoutes, store)}
          </Route>
        ) : null}
      </Route>
      <Route path="/process/payment/perfectMoney/:paymentId" element={<PerfectMoney />} />
      <Route path="*" element={<Navigate to={`/${lang}${ROUTE.APP.NOT_FOUND}/`} />} />
    </>
  )
}
