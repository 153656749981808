import { useEffect, useState } from "react";

import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DisableAutoExtendIcon } from "../../../components/icons/index";
import { Button } from "../../../components/ui/button/button.jsx";
import { Heading } from "../../../components/ui/heading/heading.jsx";
import { Modal } from "../../../components/ui/modal/modal.jsx";
import { useLang, useTranslation } from "../../../hooks/index.js";
import { BalanceService } from "../../../services";
import { ExtendService } from "../../../services/extend.service";
import { getFingerprintState, getPaymentState, getRentState } from "../../../store/selectors.js";

import { style } from "./auto-renewal.style.js";

export default function ModalsAR({
  proxyType,
  ipAddressIds,
  closeModal,
  modalType,
  onUpdateParentComponent
}) {
  const t = useTranslation();
  const { autoRenewal, autoRenewalModal } = t.pages.cabinet.orders.widgets;
  const lang = useLang();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { systemsOptions } = useSelector(getPaymentState);
  const { data } = useSelector(getFingerprintState);

  const [balance, setBalance] = useState(0);

  const paymentMethods = systemsOptions
    ? systemsOptions[lang]
        .filter((obj) => obj.code === "balance")
        .map((obj) => {
          const newObj = { ...obj };
          if (obj.code === "balance") {
            newObj.label += `: ${balance}$`;
          }
          return newObj;
        })
    : [];
  const { periods } = useSelector(getRentState);

  const rentPeriods = periods[proxyType.toUpperCase()];
  const [rentPeriodId, setRentPeriodId] = useState(rentPeriods?.[0]?.id || "");
  const [paymentMethod, setPaymentMethod] = useState(paymentMethods?.[0]?.key || "");
  const [proxyTypeId, setProxyTypeId] = useState(rentPeriods?.[0]?.proxyTypeId || "");
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [priceObj, setPriceObj] = useState({});

  useEffect(() => {
    if (rentPeriods?.length && !rentPeriodId) {
      setRentPeriodId(rentPeriods[0].id);
      setProxyTypeId(rentPeriods[0]?.proxyTypeId);
    }
    if (paymentMethods?.length && !paymentMethod) {
      setPaymentMethod(paymentMethods[0].key);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentPeriods, paymentMethods]);

  const fetchBalance = async () => {
    try {
      const res = await BalanceService.getBalance();
      const balance = `${res.data.balance}`;
      setBalance(balance);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (modalType.enable) {
      fetchBalance();
    }
  }, [modalType.enable]);

  useEffect(() => {
    if (priceObj?.totalPriceInMainCurrency) {
      const cost = priceObj?.totalPriceInMainCurrency;
      const { notEnoughMoney, topUpBalance } = autoRenewal.alert;
      if (cost > +balance) {
        const amountNeeded = Math.ceil(cost - +balance);
        enqueueSnackbar(
          <>
            <span>{notEnoughMoney} </span>
            <span
              style={{ ...style.linkBold, textDecoration: "underline", cursor: "pointer" }}
              onClick={() => navigate(`/${lang}/cabinet/balance`)}
            >
              {topUpBalance}
            </span>
            <span> {amountNeeded}$</span>
          </>,
          { variant: "error" }
        );

        setDisabledSubmitBtn(true);
      } else {
        setDisabledSubmitBtn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceObj]);

  useEffect(() => {
    const calculatePrice = setTimeout(() => {
      if (modalType.enable && rentPeriodId !== "" && paymentMethod !== "" && ipAddressIds.length) {
        const reqObj = {
          ipAddressIds,
          proxyTypeId,
          rentPeriodId,
          paymentSystemId: paymentMethod,
          fingerprint: data?.visitorId || ""
        };

        ExtendService.priceCalculateExtend(reqObj)
          .then((res) => {
            if (res.data) {
              setPriceObj(res.data);
            }

            if (!res.data.success) {
              enqueueSnackbar(autoRenewal.alert.priceError, {
                variant: "error"
              });
              setDisabledSubmitBtn(true);
            } else {
              setDisabledSubmitBtn(false);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error.message);
          });
      } else {
        setPriceObj({});
      }
    }, 300);

    return () => clearTimeout(calculatePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentPeriodId, paymentMethod, ipAddressIds]);

  const changeRentPeriod = (event) => {
    setRentPeriodId(event.target.value);
  };

  const changePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  const reqObj = {
    ipAddressIds,
    rentPeriodId,
    proxyTypeId
  };

  const submitHandler = async () => {
    reqObj.autoExtend = true;
    const { enabled, notEnabled } = autoRenewal.alert;
    try {
      const res = await ExtendService.setAutoExtendIp(reqObj);
      if (res.status === 200) {
        enqueueSnackbar(enabled, { variant: "success" });
      } else {
        enqueueSnackbar(notEnabled, { variant: "error" });
      }
      closeModal();
      onUpdateParentComponent();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error ", error);
    }
  };

  const submitHandlerDisable = async () => {
    delete reqObj.rentPeriodId;
    reqObj.autoExtend = false;
    const { disabled, notDisabled } = autoRenewal.alert;
    try {
      const res = await ExtendService.setAutoExtendIp(reqObj);

      if (res.status === 200) {
        enqueueSnackbar(disabled, { variant: "success" });
      } else {
        enqueueSnackbar(notDisabled, { variant: "error" });
      }
      closeModal();
      onUpdateParentComponent();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error ", error);
    }
  };

  return (
    <>
      {modalType.enable && (
        <Modal
          customStyles={style.modalBlock}
          visible={modalType.enable}
          cancelHandler={closeModal}
        >
          <Box sx={style.modal.upper}>
            <Heading tag="h4" variant="h4">
              {autoRenewalModal.title}
            </Heading>

            <Box component="p" sx={style.modal.subTitle}>
              {autoRenewalModal.descriptionEnableAR}
            </Box>

            <FormControl>
              <FormLabel sx={style.modal.titleBlock} id="term">
                {autoRenewalModal.term}
              </FormLabel>
              <RadioGroup
                value={rentPeriodId}
                onChange={changeRentPeriod}
                row
                aria-labelledby="term"
              >
                {rentPeriods?.map(({ id, namesByLocale }) => (
                  <FormControlLabel
                    key={id}
                    value={id}
                    control={<Radio sx={{ display: "none" }} />}
                    label={
                      <Box
                        component="span"
                        sx={[
                          style.modal.radioTerm,
                          rentPeriodId === id && style.modal.radioTerm["&.Mui-checked"]
                        ]}
                      >
                        {namesByLocale[lang]}
                      </Box>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <FormControl>
              <FormLabel sx={style.modal.titleBlock} id="paymentMethod">
                {autoRenewalModal.paymentMethod}
              </FormLabel>
              <RadioGroup
                aria-labelledby="paymentMethod"
                value={paymentMethod}
                onChange={changePaymentMethod}
              >
                {paymentMethods?.map(({ label, key }) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio sx={{ display: "none" }} />}
                    label={
                      <Box sx={style.modal.radioPayment}>
                        <Box className={`radioIcon ${paymentMethod === key ? "checked" : ""}`}>
                          {paymentMethod === key && <Box className="innerCircle" />}
                        </Box>
                        <Box
                          component="span"
                          className={`radioLabel ${paymentMethod === key ? "checked" : ""}`}
                        >
                          {label}
                        </Box>
                      </Box>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <Box sx={style.modal.price}>
              {t.other.price}&nbsp;
              <span>
                {(!!ipAddressIds.length && priceObj?.totalPriceInMainCurrency) ||
                  priceObj.totalPrice ||
                  0}
              </span>
              &nbsp;{priceObj?.currencyCodeInMainCurrency || priceObj.currencyCode || ""}
            </Box>
          </Box>
          <Box sx={style.modal.bottom}>
            <Button
              onClick={submitHandler}
              type="submit"
              variant="primary"
              ariaLabel="submit"
              size="medium"
              disabled={disabledSubmitBtn}
            >
              {autoRenewalModal.confirm}
            </Button>
            <Button onClick={closeModal} type="submit" size="medium" ariaLabel="submit">
              {autoRenewalModal.cancel}
            </Button>
          </Box>
        </Modal>
      )}

      {/* modal disable */}
      {modalType.disable && (
        <Modal
          customStyles={style.modalDisableBlock}
          visible={modalType.disable}
          cancelHandler={closeModal}
        >
          <Box sx={style.modalDisable}>
            <DisableAutoExtendIcon />
            <Heading tag="h4" variant="h4">
              {autoRenewalModal.disableAR}
            </Heading>

            <Box component="p" sx={style.modal.subTitle}>
              {autoRenewalModal.descriptionDisableAR}
            </Box>
            <Box sx={style.modalDisable.btns}>
              <Button
                onClick={submitHandlerDisable}
                type="button"
                variant="primary"
                ariaLabel="button"
                size="medium"
              >
                {autoRenewalModal.confirm}
              </Button>
              <Button onClick={closeModal} type="button" size="medium" ariaLabel="button">
                {autoRenewalModal.cancel}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
