export const getSearchParams = (location = "") => {
  if (typeof location !== "string") {
    throw new Error('Invalid location parameter')
  }

  return location.split("?")?.[1]?.split("&")?.reduce((acc, el) => {
    const [key, value] = el.split("=");

    return {
      ...acc,
      [key]: value
    }
  }, {});
}
