import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { LogoutIcon } from "../../../../components/icons/index";
import { useActions, useLang, useTranslation } from "../../../../hooks";
import { UserService } from "../../../../services";

import { style } from "./sidebar-dropdown.style";

export const SidebarDropdownLogout = () => {
  const lang = useLang();
  const navigate = useNavigate();

  const { setUser } = useActions();

  // Translations
  const t = useTranslation();

  const handleLogout = async () => {
    setUser(null);
    await UserService.logout();
    navigate(`/${lang}/`);
  };

  return (
    <Box
      component="li"
      sx={style.sidebarItem}
      className="sidebar-dropdown__item"
      onClick={handleLogout}
    >
      <Box component="span">
        <Box component="span" sx={style.sidebarItem.icon} className="sidebar-dropdown__item-icon">
          <LogoutIcon />
        </Box>
        {t.other.logout}
      </Box>
    </Box>
  );
};
