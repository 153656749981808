import { useParams } from "react-router-dom";

import { getCookie } from "../utils/helpers/cookie.helpers";
import { isSSR } from "../utils/helpers/isSSR";

export const useLang = () => {
  const { lang } = useParams();
  const cookieLang = !isSSR() ? getCookie("lang") : null;

  return lang || cookieLang;
};
