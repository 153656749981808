import { $api } from "./axios.service";

export class ExtendService {
  static priceCalculateExtend(creds) {
    return $api.post("/api/front/priceCalculate/extend", creds);
  }

  static orderExtend(creds) {
    return $api.post("/api/front/order/extend", creds);
  }
  static setAutoExtendIp(creds) {
    return $api.put("/api/front/ipAddress/autoExtend", creds);
  }
  static getAutoExtendedList() {
    return $api.get("/api/front/cabinet/autoExtend/data");
  }
}
