import { ROUTE } from "../utils/constants";

export const cabinetRoutes = [
  {
    path: `/:lang${ROUTE.CABINET.PROFILE}`,
    tag: "profile",
    component: () => import("../pages/cabinet/profile/profile")
  },
  {
    path: `/:lang${ROUTE.CABINET.REDIRECT_CHANGE_EMAIL}/:hash`,
    tag: "redirect-change-email",
    component: () => import("../pages/cabinet/redirect-change-email/redirect-change-email")
  },
  {
    path: `/:lang${ROUTE.CABINET.ORDERS}/ipv4`,
    tag: "orders/ipv4",
    component: () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang${ROUTE.CABINET.ORDERS}/ipv6`,
    tag: "orders/ipv6",
    component: () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang${ROUTE.CABINET.ORDERS}/isp`,
    tag: "orders/isp",
    component: () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang${ROUTE.CABINET.ORDERS}/mobile-proxy`,
    tag: "orders/mobile-proxy",
    component: () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang${ROUTE.CABINET.ORDERS}/inactive-partitions`,
    tag: "inactive-partitions",
    component: () => import("../pages/cabinet/orders/orders")
  },
  {
    path: `/:lang${ROUTE.CABINET.NEW_ORDER}`,
    tag: "new-order",
    component: () => import("../pages/cabinet/new-order/new-order")
  },
  {
    path: `/:lang${ROUTE.CABINET.BALANCE}`,
    tag: "balance",
    component: () => import("../pages/cabinet/balance/balance")
  },
  {
    path: `/:lang${ROUTE.CABINET.PAYMENT_HISTORY}`,
    tag: "payment-history",
    component: () => import("../pages/cabinet/payment-history/payment-history")
  },
  {
    path: `/:lang${ROUTE.CABINET.ACTIONS_HISTORY}`,
    tag: "actions-history",
    component: () => import("../pages/cabinet/actions-history/actions-history")
  },
  {
    path: `/:lang${ROUTE.CABINET.PROMO_CODES}`,
    tag: "cabinet-promocodes",
    component: () => import("../pages/cabinet/promocodes/promocodes")
  },
  {
    path: `/:lang${ROUTE.CABINET.SUBSCRIPTION}`,
    tag: "subscription",
    component: () => import("../pages/cabinet/subscription/subscription")
  },
  {
    path: `/:lang${ROUTE.CABINET.SUBSCRIPTION}/:token`,
    tag: "subscription",
    component: () => import("../pages/cabinet/subscription/subscription")
  },
  {
    path: `/:lang${ROUTE.CABINET.FAQ}`,
    tag: "cabinet-faq",
    component: () => import("../pages/cabinet/faq/faq")
  },
  {
    path: `/:lang${ROUTE.CABINET.DEVELOPERS_API}`,
    tag: "developers-api",
    component: () => import("../pages/cabinet/developers-api/developers-api")
  }
];
