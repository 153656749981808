/* eslint-disable react/display-name */
import { Box, FormControl, OutlinedInput } from "@mui/material";
import { forwardRef, useState } from "react";
import { CustomLabel } from "../../components/common/custom-label/custom-label";
import { useTranslation } from "../../hooks";
import { style } from "./heroForm.style";

// eslint-disable-next-line no-unused-vars
export const HeroInput = forwardRef(({ name, label, onChange, value, isError, placeholder, isTextArea, rows = 0, containerRef }, ref) => {

  const t = useTranslation();

  const [focus, setFocus] = useState(false);

  // helpers
  const defineStyles = () => {
    const styles = [style.formInput];

    if (isError) styles.push(style.inputError);

    if (focus) styles.push(style.focused);

    return styles;
  };

  const handleChange = (e) => {
    if (!isTextArea) {
      const onlyNums = e.target.value.replace(/[^0-9]/g, "");
      if (onlyNums.length <= 5 && onlyNums.length !== 0) {
        onChange(onlyNums);
      } else if (onlyNums.length === 0) onChange("");
      // else if (onlyNums.length === 10) {
      //   const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      //   onChange(number);
      // }
    } else {
      onChange(e.target.value);
    }
  };

  const handleClick = () => {
    ref.current.focus();
  };

  const width = value ? `${String(value).length + 0.5}ch` : null;

  return (
    <>
      <Box sx={defineStyles()} onClick={handleClick} ref={containerRef}>
        <CustomLabel htmlFor={name} sx={[style.label, style.labelCursorText]}>{label}</CustomLabel>
        <FormControl className="form-item" sx={style.inputFormControl}>
          <OutlinedInput
            inputRef={ref}
            id={name}
            name={name}
            sx={!isTextArea ? style.unsetPosition : [style.unsetPosition, style.textArea]}
            value={value ?? ""}
            onChange={handleChange}
            placeholder={`${placeholder || ""}`}
            multiline={isTextArea}
            minRows={rows}
            maxRows={10}
            endAdornment={!!value && !isTextArea ? t.other.piece : null}
            inputProps={{
              style: !isTextArea && { fontFamily: "\"Arial\", sans serif", fontWeight: 400, width }
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
        </FormControl>
      </Box>
    </>
  );
});
