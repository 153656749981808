import { useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { em } from "../utils/helpers/css.helpers";

export const useShowMore = ({ entries, limit, breakpoint = 2000 }) => {
  const isBreakpoint = useMediaQuery(`(max-width:${em(breakpoint)})`);

  const [shown, setShown] = useState(isBreakpoint ? entries?.slice(0, limit + 1) : entries);

  const handleShow = () => setShown(entries);
  const updateEntries = () => setShown(isBreakpoint ? entries?.slice(0, limit + 1) : entries)

  return {
    shown,
    handleShow,
    updateEntries,
    isShownAll: !isBreakpoint || shown?.length === entries?.length
  };
};
