import { Box } from "@mui/material";
import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";

import { DownIcon } from "../../../../components/icons/index";

import { useLang, useCollapse, useMatchedRoute, useTranslation } from "../../../../hooks";
import DynamicIcon from "../../dynamic-icon/dynamic-icon";
import { style } from "./sidebar-dropdown.style";

export const SidebarDropdownItem = ({ route, translation, parentPage, handleOpen }) => {
  const { path } = useMatchedRoute();
  const lang = useLang();

  const { collapseRef, handleCollapse, isCollapseOpen } = useCollapse({
    defaultValue: true
  });
  const t = useTranslation();

  const currentPath = path.split("/").slice(2, path.split("/").length).join("/");

  const routePath = `${parentPage}/${route.key}`;

  const handleClick = () => {
    handleCollapse();
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (isCollapseOpen && !path.split("/")[4]) handleCollapse();
  //   }, 350);
  //
  //   return () => clearTimeout(timeout);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [path]);

  const defineClasses = useMemo(() => {
    let classes = "sidebar-dropdown__item";

    if (routePath === currentPath) classes += " sidebar-dropdown__item_active";
    if (route.sub?.some((item) => `${routePath}/${item.key}` === currentPath)) {
      classes += " sidebar-dropdown__item_active";
    }

    if (isCollapseOpen) classes += " sidebar-dropdown__item_open";

    return classes;
  }, [currentPath, isCollapseOpen, route.sub, routePath]);

  const defineStyles = useMemo(() => {
    const styles = [style.sidebarItem];

    if (routePath === currentPath) styles.push(style.sidebarItem.active);
    if (route.sub?.some((item) => `${routePath}/${item.key}` === currentPath)) {
      styles.push(style.sidebarItem.active);
    }

    if (isCollapseOpen) styles.push(style.sidebarItem.open);

    return styles;
  }, [currentPath, isCollapseOpen, route.sub, routePath]);

  return (
    <Box component="span" sx={defineStyles} key={route.key} className={defineClasses}>
      {!route.sub?.length ? (
        <Box component={RouterLink} to={`/${lang}/${routePath}`} onClick={handleOpen}>
          {route.icon ? <Box component="span" sx={style.sidebarItem.icon} className="sidebar-dropdown__item-icon"><DynamicIcon iconName={route.icon}/></Box> : null}
          {t[translation][route.translation].label}
          {route.sub?.length && <Box component={DownIcon} sx={style.sidebarItem.arrow} className="sidebar-dropdown__item-arrow" />}
        </Box>
      ) : (
        <Box component="span" onClick={(e) => handleClick(e, route.sub?.length)}>
          {route.icon ? <Box component="span" sx={style.sidebarItem.icon} className="sidebar-dropdown__item-icon"><DynamicIcon iconName={route.icon}/></Box> : null}
          {t[translation][route.translation].label}
          {route.sub?.length && <Box component={DownIcon} sx={style.sidebarItem.arrow} className="sidebar-dropdown__item-arrow" />}
        </Box>
      )}
      {route.sub?.length && (
        <Box sx={style.sidebarItem.sub} ref={collapseRef} className="sidebar-dropdown__item-sub collapse">
          {route.sub?.length &&
            route.sub.map((item, idx) => (
              <Box
                component={RouterLink}
                key={`${idx}:${route.key}:${item}`}
                to={`/${lang}/${routePath}/${item.key}`}
                sx={
                  currentPath !== `${routePath}/${item.key}`
                    ? [style.sidebarItem.subItem]
                    : [style.sidebarItem.subItem, style.sidebarItem.active]
                }
                className={
                  currentPath !== `${routePath}/${item.key}`
                    ? "sidebar-dropdown__item-sub-item"
                    : "sidebar-dropdown__item-sub-item sidebar-dropdown__item-sub-item_active"
                }
                onClick={handleOpen}
              >
                {t[translation][route.translation].sub[item.translation]}
                {item?.extra ? ` ${item.extra}` : null}
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};
