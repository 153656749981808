export function swapLastTwoProperties(obj) {
   const entries = Object.entries(obj);
    if (entries.length < 2) return obj;

    const lastEntry = entries[entries.length - 1];
    const secondLastEntry = entries[entries.length - 2];

    entries[entries.length - 1] = secondLastEntry;
    entries[entries.length - 2] = lastEntry;

    return Object.fromEntries(entries);
}