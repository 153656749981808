import { createSlice } from "@reduxjs/toolkit";

import { DEFAULT_SEO } from "../../utils/constants";

import { fetchPageSeo } from "./seo.thunks";

const initialState = {
  defaultLoaded: false,
  defaultData: DEFAULT_SEO,
  data: {}, // {[localeCode]: {[tagName]: {...data}}}
  loading: false,
  errors: null,
  contentLoaded: false,
  pageParams: {tag: "", languageCode: ""},
  loadedPagesTagsByLocale: {}
};

const SeoSlice = createSlice({
  name: "seo",
  initialState,
  reducers: {
    setSeoDefault(state, action) {
      state.defaultData = {
        ...state.defaultData,
        ...action.payload
      };
      state.defaultLoaded = true;
    },
    setSeoContent(state, action) {
      state.contentLoaded = true;
      // state.data = action.payload;

      const obj = {};
      Object.entries(action.payload).forEach(([locale, dataArr]) => {
        obj[locale] = {};
        dataArr.forEach((page) => {
          obj[locale][page.tag] = page;
        });
      });
      state.data = obj;
      // console.log(obj);
    },
    setSeoPageParams(state, action) {
      state.pageParams = action.payload;
    }
  },
  extraReducers: {
    [fetchPageSeo.pending]: (state) => {
      state.loading = true;
    },
    [fetchPageSeo.rejected]: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
    [fetchPageSeo.fulfilled]: (state, action) => {
      const { languageCode, tag, tagPostfix } = action.payload;
      const tagWithPostfix = tag + tagPostfix;

      state.loadedPagesTagsByLocale = {
        ...state.loadedPagesTagsByLocale,
        [languageCode]: [...(state.loadedPagesTagsByLocale[languageCode] ?? []), tagWithPostfix]
      };

      if (action.payload.data) {
        state.data = {
          ...state.data,
          [languageCode]: {
            ...state.data[languageCode],
            [tagWithPostfix]: action.payload.data
          }
        };
      }

      state.loading = false;
    }
  }
});

export const SeoActions = {
  ...SeoSlice.actions,
  fetchPageSeo
};

export default SeoSlice.reducer;
