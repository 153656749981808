import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../services";

export const fetchPageSeo = createAsyncThunk(
  "seo/fetchPageSeo",
  async (values, { rejectWithValue }) => {
    try {
      const response = await ApiService.getPageSeoV2(values);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return {
        data: response.data,
        tag: values.tag,
        languageCode: values.languageCode,
        tagPostfix: values.tagPostfix
      };
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
export const fetchContentSeo = createAsyncThunk(
  "seo/fetchContentSeo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getSeoContent();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
