import { Route } from "react-router-dom";
import { collectPageSeoForSsr } from "./serverSideState.helpers";
import ErrorPage from "../../pages/common/error/error";
import React from "react";

export const renderRoutes = (routes, store) =>
  routes.map((route) => {

    return <Route
      key={route.path}
      path={route.path}

      errorElement={<ErrorPage />}
      hydrateFallbackElement={<>Loading...</>}

      // add change page seo to local /
      loader={async (args) => {
        const { params, request } = args;
        const { pathname, search } = new URL(request.url);

        const seoPageParams = await collectPageSeoForSsr(store, {
          tag: route.tag,
          locale: params.lang,
          location: pathname.slice(3) + search,
          params: params
        });

        return {
          seoPageParams
        };
      }}

      lazy={async () => {
        const { default: Component } = await route.component();

        return {
          element: (
            <Component tag={route.tag} />
          )
        };
      }}
    />;
  });

