import { createRoot, hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, createRoutesFromElements, matchRoutes, RouterProvider } from "react-router-dom";

import { setupStore } from "./store/index";

import "./assets/styles/globals.scss";

import { App } from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { appTheme } from "./theme";
import { ApiService } from "./services";
import { getRoutes } from "./routes/routes";
import { getIsToolsActive } from "./store/tools/tools.selectors";
import { getTranslationDefaultLocaleState, getTranslationLocales } from "./store/translation/translations.selectors";
import { getCookie } from "./utils/helpers/cookie.helpers";
import SnackbarProvider from "./components/ui/snackbar/snackbar-provider";
const rootEl = document.getElementById("root");

const getUserLocale = (defaultLocale, translationLocales) => {

  const pathnameLang = translationLocales.indexOf(window.location.pathname.slice(1, 3)) != -1 ? window.location.pathname.slice(1, 3) : null;
  const cookieLang = getCookie("lang") || null;
  const navigatorLang = translationLocales.indexOf(navigator.language?.slice(0, 2)) != -1 ? navigator.language?.slice(0, 2) : null;

  // console.log(pathnameLang);
  // if (pathnameLang) {
  //   window.localStorage.setItem("lang", pathnameLang);
  //   // setCookie("lang", pathnameLang);
  // } else if (!pathnameLang && !cookieLang) {
  //   window.localStorage.setItem("lang", navigatorLang || defaultLocale);
  //   // setCookie("lang", navigatorLang || defaultLocale);
  // }

  return pathnameLang || cookieLang || navigatorLang || defaultLocale;
}

const getApp = async (initialState) => {
  const store = setupStore(initialState);
  const state = store.getState();
  const isToolsActive = getIsToolsActive(state);
  const defaultLocale = getTranslationDefaultLocaleState(state)
  // const lang = document
  //   .querySelector("html")
  //   .getAttribute("lang") || defaultLocale;

  const locales = getTranslationLocales(state);

  const lang = getUserLocale(defaultLocale, locales);


  const routes = createRoutesFromElements(
    getRoutes({
      lang,
      isToolsActive,
      store
    })
  )

  let lazyMatches = matchRoutes(routes, window.location)?.filter(
    (m) => m.route.lazy
  );

  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        let routeModule = await m.route.lazy();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      })
    );
  }

  return ({
    app: (
      <ThemeProvider theme={appTheme}>
        <Provider store={store}>
          <SnackbarProvider>
            <App
              routerProvider={RouterProvider}
              routerProps={{
                router: createBrowserRouter(routes)
              }}/>
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    ),
    locale: lang
  })
};

const optionRender = async (initialState = {}) => {
  const app = await getApp(initialState);
  // console.log(app.locale, document.querySelector("html").getAttribute("lang"));
  if (rootEl.hasChildNodes() && app.locale === document.querySelector("html").getAttribute("lang")) {
    return hydrateRoot(rootEl, app.app)
  } else {
    const root = createRoot(rootEl);

    return root.render(app.app)
  }
}

const initStore = async () => {
  try {
    const contentId = document.querySelector(
      `meta[name="ssr-content-id"]`
    ).content;

    if (contentId) {
      const req = await ApiService.cacheSSR(contentId);

      if (req.data.state) {
        return optionRender(req.data.state);
      }
    }

    return optionRender(window.__PRELOADED_STATE__);
  } catch (e) {
    return optionRender(window.__PRELOADED_STATE__);
  } finally {
    delete window.__PRELOADED_STATE__;
    document.getElementById("preloaded-state")?.remove()
  }
}

initStore();
