import { startTransition, useEffect } from "react";
import { useActions } from "../../hooks";
import { UserService } from "../../services";

const AuthorizationCheck = () => {
  const { setUser } = useActions();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      startTransition(() => setUser(user));
    }

    UserService.authorizationCheck().then((res) => {
      if (!res.data) {
        startTransition(() => setUser(null));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default AuthorizationCheck;
