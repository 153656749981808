
// const SelectArrow = (props) => <Box {...props} ><DownIcon /></Box>;

import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Box, IconButton, InputBase } from "@mui/material";

import { XMark } from "../../../assets/images/icons/XMark";

import { getCountryFlagIso2 } from "../../../utils/helpers/get-country-flag";
import IpInputMask from "../ip-input-mask/ip-input-mask";
import { style } from "./select-list.style";

export const SelectList = ({
  name,
  label,
  placeholder = null,
  options = [],
  withIcon = false,
  showIconInContainer = false,
  customStyles,
  onChange,
  selectedIndex,
  isTextInputKey = null,
  textInputChangeHandler,
  textInputValue,
  cancelHandler,
  isOuterLabel = false,
  isTableSelect = false,
  error,
  errorText,
  disabled = false,
  isIpInput = false,
  isOpen = false,
  onOpen,
  onClose,
  isPortal = false,
  ...other
}) => {
  const selectRef = useRef();
  const innerLabelRef = useRef();
  const [labelWidth, setLabelWidth] = useState(0);
  const [condition, setCondition] = useState(false);

  const [menuIsOpen, setMenuIsOpen] = useState(isOpen);
  const [documentObj, setDocumentObj] = useState(null);

  // const menuIsOpen = true;

  // const setMenuIsOpen = () => {};

  const [textInputFocused, setTextInputFocused] = useState(false);

  const [inFocus, setInFocus] = useState(false);

  // styles for animated open/close state and other
  const selectStyles = (menuIsOpen, labelWidth) => ({
    menu: (provided) => ({
      ...provided,
      opacity: menuIsOpen ? 1 : 0, // Set opacity based on menu state
      transform: menuIsOpen ? "translateY(0rem)" : "translateY(-1rem)",
      transition: "opacity 0.3s ease, transform 0.3s ease", // Apply transition to opacity
      visibility: menuIsOpen ? "visible" : "hidden",
      pointerEvents: menuIsOpen ? "unset" : "none",
      left: "0",
      margin: isTableSelect ? "0 -15px" : "unset",
      width: isTableSelect ? "calc(100% + 30px)" : "100%"
      // borderRadius: "1.2rem",
      // overflow: "hidden"

    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "20rem",
      borderRadius: "1.2rem",
      background: "#fff",
      boxShadow: "0 2px 22px rgb(95 103 128 / 14%)",
      overflowX: "hidden",
      width: "100%",
      paddingTop: "1.3rem",
      paddingBottom: "1.3rem"
      // marginRight: isTableSelect ? "0" : "10px"

    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      fontSize: "1.4rem",
      cursor: isSelected ? "default" : "pointer",
      // var(--clr-text-400)
      color: "#63637C",
      fontWeight: "400",
      display: "flex",
      alignItems: "center",
      padding: withIcon ? "0.5rem 1.6rem" : "0.5rem 3rem",
      transition: "0.3s",
      backgroundColor: isSelected ? "#DCE5FD" : "#fff",
      pointerEvents: isSelected && "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      position: "relative",
      zIndex: "1",
      "&:hover": {
        // var(--clr-default-300)
        backgroundColor: "#EEF2FC"
      },

      "& span": {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "1.8rem"
      }

    }),
    control: (provided) => ({
      ...provided,
      paddingLeft: `${labelWidth}px`
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      transition: "0.3s ease", // Apply transition to opacity
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)"
    }),
    valueContainer: (provided) => ({
      ...provided,
      "& .select__img": {
        display: !showIconInContainer ? "none" : "block"
      },

      "& span": {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#75808F"
    })
  });

  const styles = [customStyles, style.selectWrapper];
  if (isOuterLabel) styles.push(style.selectOuterLabel);
  else styles.push(style.selectInnerLabel);

  if (error) styles.push(style.error);

  if (disabled) styles.push(style.disabled);

  if (isTableSelect) styles.push(style.tableSelect);

  if (textInputFocused || inFocus) styles.push(style.focused);

  // custom select option component
  const formatOptionLabel = ({ label, code }) => (
    <>
      {withIcon && (
        <Box sx={style.dropItemImg} className="select__img">
          <img src={getCountryFlagIso2(code)} alt={code} />
        </Box>
      )}
      <span className="label">{label}</span>
    </>
  );

  useEffect(() => {
    setDocumentObj(document);
  }, []);
  useEffect(() => {
    setMenuIsOpen(isOpen);
    if (isOpen) selectRef.current.focus();
  }, [isOpen]);

  // set inner label width if exist
  useEffect(() => {
    if (innerLabelRef?.current && !isOuterLabel) {
      setLabelWidth(innerLabelRef.current.clientWidth + 5);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerLabelRef?.current?.clientWidth]);

  // check isTextInputKey condition
  useEffect(() => {
    if (isTextInputKey && selectedIndex !== null) {
      setCondition(options[selectedIndex].tag === isTextInputKey);
    } else setCondition(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  return (
    <Box {...other}>
      {isOuterLabel && !!label && (
        <Box component="label" sx={[style.label, style.outerLabel]}>
          {label}
        </Box>
      )}

      {!condition ? (
        <Box
          sx={styles}
          className={menuIsOpen ? "form-item open" : "form-item"}
          onClick={() => {
            if (selectRef?.current) {
              // if (menuIsOpen) return;
              // setMenuIsOpen(() => !menuIsOpen);
              if (menuIsOpen) {
                selectRef.current.blur();
                setMenuIsOpen(false);
                if (onClose) {
                  onClose(setMenuIsOpen);
                }
              } else {
                selectRef.current.focus();
                setMenuIsOpen(true);
                if (onOpen) {
                  onOpen(setMenuIsOpen);
                }
              }
            }
          }}
          onTouchEnd={() => {
            if (selectRef?.current) {
              if (menuIsOpen) return;
              setMenuIsOpen(() => true);
              // if (menuIsOpen) selectRef.current.blur();
              selectRef.current.focus();
              if (onOpen) onOpen(setMenuIsOpen);
            }
          }}
        >
          {!isOuterLabel && !!label && (
            <Box
              component="label"
              ref={innerLabelRef}
              sx={[style.label, style.innerLabel, { position: "absolute" }]}
            >
              {label}
            </Box>
          )}

          <Select
            aria-label={name}
            ref={selectRef}
            unstyled
            className="react-select__container"
            classNamePrefix="react-select"
            menuIsOpen
            isSearchable={false}
            getOptionValue={(opt) => opt.key}
            styles={selectStyles(menuIsOpen, labelWidth)}
            onBlur={() => {
              setMenuIsOpen(false);
              if (onClose) onClose(setMenuIsOpen);
            }}
            menuPortalTarget={(isTableSelect || isPortal) && documentObj?.body}
            options={options}
            inputId={name}
            instanceId={name}
            value={(options.length && selectedIndex !== null) ? options[selectedIndex] : null}
            onChange={onChange}
            placeholder={placeholder}
            formatOptionLabel={formatOptionLabel}
            menuShouldScrollIntoView={false}
          />
        </Box>
      ) : (
        <Box>
          {isIpInput ? (
            <Box
              onClick={() => {
                setInFocus(true);
              }}
              sx={[...styles, { cursor: "text" }]}
            >
              <Box
                component="label"
                ref={innerLabelRef}
                sx={[style.label, style.innerLabel, { cursor: "text" }]}
              >
                IP:
              </Box>

              <IpInputMask
                useFocus={() => [inFocus, setInFocus]}
                onChange={textInputChangeHandler}
                value={textInputValue}
                sx={style.ipInputV2}
              />

              <IconButton sx={style.iconButton} onClick={cancelHandler}>
                <XMark />
              </IconButton>
            </Box>
          ) : (
            <InputBase
              sx={styles}
              value={textInputValue}
              onChange={textInputChangeHandler}
              startAdornment={
                !isOuterLabel ? (
                  <Box
                    component="label"
                    ref={innerLabelRef}
                    sx={[style.label, style.innerLabel]}
                  >
                    {label}
                  </Box>
                ) : null
              }
              endAdornment={
                <IconButton sx={style.iconButton} onClick={cancelHandler}>
                  <XMark />
                </IconButton>
              }
              onFocus={() => setTextInputFocused(true)}
              onBlur={() => setTextInputFocused(false)}
            />
          )}
        </Box>
      )}

      {error && !!errorText && (
        <Box component="p" sx={style.errorText}>
          {errorText}
        </Box>
      )}
    </Box>
  );
};
