import { setCaptchaTokenToRequestHeaders } from "../utils/helpers";

import { $api } from "./axios.service";

export class UserService {
  static adminUser() {
    return $api.get("/api/admin/user/adminUser");
  }

  static login(creds, cfToken) {
    return $api.post(
      "/api/login",
      {},
      {
        params: creds,
        headers: setCaptchaTokenToRequestHeaders(cfToken)
      }
    );
  }
  static logout() {
    return $api.post("/api/logout");
  }

  static register(creds, cfToken) {
    return $api.post("/api/front/cabinet/register", creds, {
      headers: setCaptchaTokenToRequestHeaders(cfToken)
    });
  }

  static createDefaultUser(creds, cfToken) {
    return $api.post("/api/admin/user/createDefaultUser", creds, {
      headers: setCaptchaTokenToRequestHeaders(cfToken)
    });
  }

  static getUser() {
    return $api.get("/api/front/cabinet/profile");
  }

  static changePassword(creds, cfToken) {
    return $api.post("/api/front/cabinet/changePassword", creds, {
      headers: setCaptchaTokenToRequestHeaders(cfToken)
    });
  }

  static sendPasswordLink(email, cfToken) {
    return $api.get(`/api/front/cabinet/sendPasswordLink`, {
      params: { email },
      headers: setCaptchaTokenToRequestHeaders(cfToken)
    });
  }

  static restorePassword(creds, cfToken) {
    return $api.post("/api/front/cabinet/restorePassword", creds, {
      headers: setCaptchaTokenToRequestHeaders(cfToken)
    });
  }

  static isAuthorized() {
    return $api.get("/api/front/user/authorized");
  }

  static authorizationCheck() {
    return $api.get("/api/front/user/authorization/check");
  }

  static isBlocked(values) {
    return $api.post("/api/front/user/blocked", values);
  }

  static sendEmailLink(newEmail, cfToken) {
    return $api.get(`/api/front/cabinet/sendEmailLink`, {
      params: { newEmail },
      headers: setCaptchaTokenToRequestHeaders(cfToken)
    });
  }

  static changeEmail(token) {
    return $api.get(`/api/front/cabinet/changeEmail`, { params: { token } });
  }
}
