import axios from "axios";
import { isSSR } from "../utils/helpers/isSSR";

export let $api;
if (isSSR()) {
  console.log('api url: ', process.env.REACT_APP_SSR_API_URL);
  $api = axios.create({
    baseURL: process.env.REACT_APP_SSR_API_URL
  })
} else {
  $api = axios.create();

  $api.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
      if (error.response?.status === 401) {
        window.localStorage.removeItem("user");

        // if(error.config.url !== "/api/login") {
        //   window.location.href = "/";
        // }
      }

      return Promise.reject(error);
    }
  )
}
