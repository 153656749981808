import { ROUTE } from "../utils/constants";

export const authRoutes = [
  {
    path: `/:lang${ROUTE.AUTH.LOGIN}`,
    tag: "login",
    component: (() => import("../pages/auth/login/login"))
  },
  {
    path: `/:lang${ROUTE.AUTH.REGISTER}`,
    tag: "register",
    component: (() => import("../pages/auth/register/register"))
  },
  {
    path: `/:lang${ROUTE.AUTH.FORGOT}`,
    tag: "forgot",
    component: (() => import("../pages/auth/forgot/forgot"))
  },
  {
    path: `/:lang${ROUTE.AUTH.RECOVERY}/:hash`,
    tag: "restore",
    component: (() => import("../pages/auth/forgot/forgot"))
  }
];
