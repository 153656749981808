import { appTheme } from "../../../theme";

export const style = {
  count: {
    fontWeight: "500",
    fontSize: "1.4rem",
    color: "var(--clr-text-400)",
    "& > span": {
      color: "var(--clr-primary-400)"
    }
  },
  switch: {
    paddingLeft: "0.7rem",
    marginTop: "1rem",
    "& .MuiSwitch-root": {
      padding: "6px 4px"
    },

    "& .MuiSwitch-track": {
      borderRadius: 12,
      border: "1px solid #D9E2F8",
      backgroundColor: "transparent",
      height: 26
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      color: "#D9E2F8",
      marginTop: "2px"
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: "var(--clr-primary-400)"
        }
      }
    },
    "& .MuiTypography-root": {
      marginLeft: "1rem",
      color: "var(--clr-text-900)",
      fontSize: "16px"
    }
  },
  modalBlock: { maxWidth: "48rem", overflow: "hidden" },
  modal: {
    upper: {
      padding: "3.2rem 3.6rem 2rem 3.6rem ",
      background: "#ffffff",
      "& > .heading": {
        marginBottom: "0.7rem",
        fontSize: "2.4rem",
        color: "var(--clr-text-900)"
      },

      [appTheme.breakpoints.down("bp460")]: {
        paddingRight: "2rem",
        paddingLeft: "2rem",
        paddingBottom: "2rem"
      }
    },
    bottom: {
      padding: "2.4rem 3.6rem",
      backgroundColor: "var(--clr-default-200)",
      display: "flex",
      gap: "2.4rem",
      [appTheme.breakpoints.down("bp460")]: {
        gap: "0.4rem"
      },
      "& > .button": { flex: "1 1 calc(50% - 1.2rem)" },
      [appTheme.breakpoints.down("bp460")]: {
        padding: "2rem",
        gap: "1.5rem"
      }
    },

    subTitle: {
      marginBottom: "2.4rem",
      fontWeight: "400",
      fontSize: "1.4rem",
      color: "var(--clr-text-400)"
    },
    titleBlock: {
      fontFamily: "Gilroy",
      color: "var(--clr-text-900)",
      fontSize: "1.8rem",
      fontWeight: 500,
      lineHeight: "2.4rem",
      marginBottom: "1.6rem"
    },
    radioTerm: {
      display: "inline-flex",
      padding: "1rem 1.2rem",
      fontFamily: "Gilroy",
      borderRadius: "1.2rem",
      backgroundColor: "var(--clr-default-200)",
      color: "var(--clr-text-900)",
      fontSize: "1.6rem",
      fontWeight: 400,
      lineHeight: 0.8,
      cursor: "pointer",
      margin: "0 0rem 1.2rem 1.2rem",
      "&.Mui-checked": {
        outline: "1px solid var(--clr-primary-400)",
        fontWeight: 500
      }
    },
    radioPayment: {
      marginLeft: "1.1rem",
      display: "flex",
      alignItems: "center",
      fontSize: "1.6rem",
      fontWeight: 400,
      cursor: "pointer",
      marginBottom: "1rem",
      fontFamily: "Gilroy",
      color: "var(--clr-text-900)",

      "& .radioIcon": {
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        border: "2px solid #D7DBEC",
        marginRight: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      "& .radioIcon.checked": {
        border: "none",
        backgroundColor: "var(--clr-primary-400)"
      },
      "& .radioLabel.checked": {
        fontWeight: 500
      },
      "& .radioIcon.checked .innerCircle": {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#FFFFFF",
        fontWeight: 500
      }
    },
    price: {
      marginTop: 1,
      textAlign: "center"
    }
  },
  modalDisableBlock: { maxWidth: "54rem", overflow: "hidden" },
  modalDisable: {
    padding: "4.5rem 7.5rem 4.5rem 7.5rem ",
    textAlign: "center",
    "& > .heading": {
      margin: "3rem 0 1rem 0",
      fontSize: "2.4rem",
      color: "var(--clr-text-900)"
    },

    [appTheme.breakpoints.down("bp460")]: {
      padding: "3.4rem 3rem"
    },
    btns: {
      display: "flex",
      gap: "2.4rem",
      [appTheme.breakpoints.down("bp460")]: {
        gap: "0.4rem"
      },
      "& > .button": { flex: "1 1 calc(50% - 1.2rem)" }
    }
  },
  linkBold: { textDecoration: "underline", cursor: "pointer", fontWeight: 600 },
  autoExtendedList: {
    margin: "-2rem 0 2rem 0",
    "& p": {
      color: "var(--clr-text-900)",
      fontWeight: 400,
      marginBottom: "1rem",
      [appTheme.breakpoints.down("bp460")]: {
        fontSize: "1.4rem"
      }
    }
  }
};
