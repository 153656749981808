/*eslint arrow-body-style: ["off", "as-needed"]*/
import { Suspense, lazy } from "react";

import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

import Metadate from "../components/common/metadate/metadate";
import { ScrollToTop } from "../components/special";
import AnimatedOutlet from "../components/special/animated-outlet";

const Header = lazy(() => import("../components/common/header/header"));
const MobileMenu = lazy(() => import("../components/common/mobile-menu/mobile-menu"));

const IndexLayout = () => {
  // console.count("IndexLayout");
  const { pathname, search } = useLocation();

  const canonicalUrl = `https://youproxy.io${pathname + search}`;

  return (
    <>
      <ScrollToTop />
      {/* <RedirectFromRoot /> */}
      {/*Moved PaymentSystemsLoader to header -> logo*/}
      <Metadate canonicalUrl={canonicalUrl} />
      <Box className="wrapper">
        <Suspense fallback={<></>}>
          <Header />
        </Suspense>
        <AnimatedOutlet />
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up("bp1200")]: {
              display: "none"
            }
          })}
        >
          <Suspense fallback={<></>}>
            <MobileMenu />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default IndexLayout;
