import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { checkIpAgainstRanges } from "../components/common/ip-input-mask/utils";
import { ApiService } from "../services";
import { getFingerprintState } from "../store/fingerprint/fingerprint.selectors";
import { getProxyState } from "../store/proxy/proxy.selectors";
import { CALC_TYPES, IP_REG, MAIL_REG } from "../utils/constants";
import { getPromocodeWarning } from "../utils/helpers/order.helpers";

import { useTranslation } from "./use-translations";

const defaultOrderData = {
  proxyType: "",
  proxyTypeId: "",
  proxyCountry: "",
  proxyRentalPeriod: "",
  proxyCount: "",
  promoCode: "",
  proxyGoal: "",
  proxyCustomGoal: "",
  proxyMail: "",
  proxyPayment: "",
  proxyAuth: "login",
  proxyIpAuth: "",
  proxyProtocol: "",
  mobileOperatorId: "",
  modemRotationMin: "",
  currencyId: "" // якщо сurrencyId поміняти, то нічого з цього не вийде, воно не буде працювати з іншими данними, окрім головної валюти
};
const defaultPriceObj = {
  loading: false,
  type: null,
  // content
  promoCodeWarn: null,
  success: true,
  error: null,
  warnings: null,
  validPromoCode: true,
  currencyCode: "",
  quantity: 0,
  priceForOne: 0,
  priceForOneWithDiscount: 0,
  totalPrice: 0,
  totalDiscountInPercent: 0,
  minPriceForPaymentSystem: 0
};
const useOrder = (initialData) => {
  const t = useTranslation();

  const [order, setOrder] = useState(() => {
    return initialData ? { ...defaultOrderData, ...initialData } : defaultOrderData;
  });
  const [orderErrors, setOrderErrors] = useState({});
  const [orderWarnings, setOrderWarnings] = useState({});

  const [minCount, setMinCount] = useState(1);
  const [price, setPrice] = useState(defaultPriceObj);
  const [canShowTooltip, setCanShowTooltip] = useState(false);
  const { data } = useSelector(getFingerprintState);
  const { proxyMinQuantity } = useSelector(getProxyState);

  const timerRef = useRef();
  const requestRef = useRef(null);
  // Update order if initial data changed
  useEffect(() => {
    if (initialData) {
      setOrder((prevState) => {
        const equal = Object.entries(initialData).every(([key, value]) => prevState[key] === value);

        if (!equal) {
          setPrice(defaultPriceObj);
          setOrderErrors({});
          return { ...defaultOrderData, ...initialData };
        } else {
          return { ...prevState, ...initialData };
        }
      });
    }
  }, [initialData]);
  // Calculate price with debounce
  useEffect(() => {
    if (price.type && !requestRef.current) {
      timerRef.current = setTimeout(() => {
        if (!checkIsValidDataForPriceCalculation(price.type)) {
          return;
        }

        setPrice((prevState) => ({
          ...prevState,
          loading: true
        }));
        let apiMethood = "";
        let payload = {
          proxyTypeId: order.proxyTypeId,
          countryId: order.proxyCountry,
          quantity: parseInt(order.proxyCount),
          promoCode: order.promoCode !== "" ? order.promoCode : null,
          rentPeriodId: order.proxyRentalPeriod
        };

        if (price.type === CALC_TYPES.BASIC) {
          apiMethood = "priceCalculateProvisional";
        } else if (price.type === CALC_TYPES.FULL || price.type === CALC_TYPES.MOBILE_BASIC) {
          apiMethood = "priceCalculate";
          payload = {
            ...payload,
            goalCustomName: order.proxyCustomGoal !== "" ? order.proxyCustomGoal : null,
            goalId: null,
            currencyId: order.currencyId !== "" ? order.currencyId : null,
            mobileOperatorId: order.mobileOperatorId ? order.mobileOperatorId : null,
            paymentSystemId: order.proxyPayment !== "" ? order.proxyPayment : null,
            modemRotationMin: order.modemRotationMin === "" ? -1 : order.modemRotationMin,
            fingerprint: data?.visitorId || null,
            clientEmail: order.proxyMail !== "" ? order.proxyMail : null,
            provisionallyCalculate: price.type === CALC_TYPES.MOBILE_BASIC
          };
        }

        requestRef.current = new AbortController();

        ApiService[apiMethood](payload, {
          signal: requestRef.current.signal
        })
          .then(({ data }) => {
            if (!data.success) {
              return Promise.reject(data);
            }

            setPrice({
              ...data,
              validPromoCode: order.promoCode ? data.validPromoCode : true,
              loading: false,
              type: null
            });
          })
          .catch((data) => {
            const type = price.type;

            setPrice((prevState) => {
              return {
                ...prevState,
                loading: false,
                type: null
              };
            });

            if (requestRef.current.signal.aborted) {
              calculatePrice(type);
            }

            console.log("Error:", data.code);
          })
          .finally(() => {
            requestRef.current = null;
          });
        if (!canShowTooltip) setCanShowTooltip(true);
      }, 500);

      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [price.type, order, requestRef.current]);
  // Set min count on proxyType change, if old !== new || old < newMinCount
  useEffect(() => {
    const newMinCount = proxyMinQuantity[order.proxyType.toUpperCase()] ?? 1;

    if (newMinCount !== minCount) setMinCount(newMinCount);

    if (order.proxyCount !== newMinCount && order.proxyCount < newMinCount) {
      setOrder((prevState) => ({
        ...prevState,
        proxyCount: newMinCount
      }));
    }
  }, [order.proxyType]);
  // set min count after 2sec if count < minCount
  useEffect(() => {
    const updateCount = setTimeout(() => {
      if (!order.proxyCount || order.proxyCount < minCount) {
        setOrder((prevState) => ({
          ...prevState,
          proxyCount: minCount
        }));
      }
    }, 2000);

    return () => clearTimeout(updateCount);
  }, [order.proxyCount]);
  // trigger promocode warnings
  useEffect(() => {
    let message = getPromocodeWarning(price.promoCodeWarn, t);

    if (message) {
      setOrderWarnings((p) => ({ ...p, promoCode: message }));
    }
  }, [price.promoCodeWarn]);
  // set valid promocode if order.promoCode changed
  useEffect(() => {
    if (!price.loading && !price.validPromoCode) {
      setPrice((prevState) => ({
        ...prevState,
        validPromoCode: true
      }));
    }
  }, [order.promoCode]);
  useEffect(() => {
    if (price.warnings && price.warnings?.indexOf("INCORRECT_GOAL") !== -1) {
      setOrderErrors((prevState) => ({
        ...prevState,
        customGoal: t.form.serverErrors.incorrectGoal
      }));
    } else if (orderErrors["customGoal"] === t.form.serverErrors.incorrectGoal) {
      setOrderErrors((prevState) => ({
        ...prevState,
        customGoal: false
      }));
    }

    if (price.warnings && price.warnings?.indexOf("BALANCE_LOW") !== -1) {
      setOrderErrors((prevState) => ({
        ...prevState,
        paymentType: t.form.serverErrors.balance
      }));
    } else if (orderErrors["paymentType"] === t.form.serverErrors.balance) {
      setOrderErrors((prevState) => ({
        ...prevState,
        paymentType: false
      }));
    }

    if (price.warnings && price.warnings?.indexOf("PAYMENT_SYSTEM_MIN_PRICE") !== -1) {
      setOrderErrors((prevState) => ({
        ...prevState,
        paymentType: t.form.serverErrors.minPrice.replace(
          "{{price}}",
          price.minPriceForPaymentSystem
        )
      }));
    } else if (
      orderErrors["paymentType"] ===
      t.form.serverErrors.minPrice.replace("{{price}}", price.minPriceForPaymentSystem)
    ) {
      setOrderErrors((prevState) => ({
        ...prevState,
        paymentType: false
      }));
    }
  }, [price.warnings]);
  useEffect(() => {
    orderErrors["paymentType"] = false;
  }, [order.proxyPayment]);

  const clearOrderData = (newData = {}) => {
    setOrder({ ...defaultOrderData, ...newData });
    setPrice(defaultPriceObj);
    setOrderErrors({});
  };
  const validateOrder = (fields = []) => {
    const errors = {};

    errors.country = !order.proxyCountry;
    errors.customGoal =
      (order.proxyType !== "MOBILE" && !order.proxyCustomGoal) ||
      (price.warnings && price.warnings?.indexOf("INCORRECT_GOAL") !== -1
        ? t.form.serverErrors.incorrectGoal
        : false);
    errors.mobileOperator = order.proxyType === "MOBILE" && !order.mobileOperatorId;
    errors.modemRotationMin = order.proxyType === "MOBILE" && order.modemRotationMin === "";
    errors.rentalPeriod = !order.proxyRentalPeriod;
    errors.quantity =
      !order.proxyCount ||
      (order.proxyCount < minCount
        ? t.form.rules.quantity.minQuantity.replace("{{quantity}}", minCount)
        : false);
    errors.paymentType =
      !order.proxyPayment ||
      (price.warnings && price.warnings?.indexOf("BALANCE_LOW") !== -1
        ? t.form.serverErrors.balance
        : false) ||
      (price.warnings && price.warnings?.indexOf("PAYMENT_SYSTEM_MIN_PRICE") !== -1
        ? t.form.serverErrors.minPrice.replace("{{price}}", price.minPriceForPaymentSystem)
        : false);
    // errors.authType =
    //   !order.proxyAuth || (order.proxyAuth === "ip" && !ipReg.test(order.proxyIpAuth));
    errors.authType = !order.proxyAuth;
    errors.authIp =
      order.proxyAuth === "ip"
        ? !IP_REG.test(order.proxyIpAuth) || checkIpAgainstRanges(order.proxyIpAuth)
        : false;
    errors.proxyProtocol = order.proxyType === "IPV6" && !order.proxyProtocol;
    errors.email = !order.proxyMail || !MAIL_REG.test(order.proxyMail);

    const newErrors = fields.length
      ? Object.fromEntries(fields.map((key) => [key, errors[key]]))
      : errors;

    const finalErrors = {
      ...orderErrors,
      ...newErrors
    };
    setOrderErrors(finalErrors);

    return finalErrors;
  };
  const resetPriceCalculation = () => {
    clearTimeout(timerRef.current);
    setPrice((prevState) => ({
      ...prevState,
      type: null,
      loading: false
    }));
  };
  const checkIsValidDataForPriceCalculation = (type) => {
    if (
      !order.proxyTypeId ||
      !order.proxyCountry ||
      !order.proxyCount ||
      order.proxyCount < minCount ||
      !order.proxyRentalPeriod
    ) {
      return false;
    }

    if (type === CALC_TYPES.BASIC) {
    } else if (type === CALC_TYPES.FULL) {
      if (
        (order.proxyType !== "MOBILE" && !order.proxyCustomGoal) ||
        !order.proxyPayment ||
        !order.proxyMail
      ) {
        return false;
      }
      if (order.proxyType === "IPV4") {
      } else if (order.proxyType === "IPV6" && !order.proxyProtocol) {
        resetPriceCalculation();
        return;
      } else if (
        order.proxyType === "MOBILE" &&
        (!order.mobileOperatorId || order.modemRotationMin === "")
      ) {
        return false;
      }
    } else if (type === CALC_TYPES.MOBILE_BASIC) {
      if (order.proxyType !== "MOBILE" || !order.mobileOperatorId) {
        return false;
      }
    }
    return true;
  };
  const calculatePrice = (type) => {
    if (!type) {
      console.log("type parameter is required!");
      return;
    }
    if (requestRef.current) {
      requestRef.current.abort(new Error("Aborted by by new request"));
    }

    // setPrice((prevState) => ({...prevState,
    //   priceForOne: 0,
    //   priceForOneWithDiscount: 0,
    //   totalPrice: 0,
    //   totalDiscountInPercent: 0
    // }))
    // Basic validation

    if (!checkIsValidDataForPriceCalculation(type)) {
      resetPriceCalculation();
      return;
    }

    setPrice((prevState) => ({
      ...prevState,
      type
    }));
  };
  const clearPrice = () => {
    setPrice(defaultPriceObj);
  };

  return {
    data: order,
    setData: setOrder,
    dataErrors: orderErrors,
    setErrors: setOrderErrors,
    dataWarnings: orderWarnings,
    validateData: validateOrder,
    clearData: clearOrderData,
    minCount,
    price,
    calculatePrice: calculatePrice,
    clearPrice: clearPrice,
    canShowTooltip
  };
};

export default useOrder;
