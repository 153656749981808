import { createSelector } from "@reduxjs/toolkit";

export const getTranslationState = (state) => state.translation;
export const getTranslationDefaultLocaleState = (state) => state.translation.defaultLocale;
export const getTranslationLocales = createSelector(
  [getTranslationState],
  (state) => Object.keys(state.translations)
);
export const getTranslationByLocaleState = (locale) => (state) => createSelector(
  [getTranslationState, (state, locale) => locale],
  (state, locale) => {
    return state.translations[locale];
  }
)(state, locale);
