import { createSlice } from "@reduxjs/toolkit"
import { fetchMobile } from "./mobile.thunks";

export const initialMobileState = {
  loading: false,
  errors: [],
  countries: [],
  isDataLoaded: false
}

const MobileSlice = createSlice({
  name: "mobile",
  initialState: initialMobileState,
  reducers: {
  },
  extraReducers: {
    [fetchMobile.pending]: (state) => {
      state.loading = true;
    },
    [fetchMobile.rejected]: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
    [fetchMobile.fulfilled]: (state, action) => {
      state.countries = Object.keys(action.payload).map((el) => ({...action.payload[el]}));
      state.loading = false;
    }
  }
});

export const MobileActions = { ...MobileSlice.actions, fetchMobile };

export default MobileSlice.reducer;
