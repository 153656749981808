import { Box, ButtonBase, CircularProgress, Link as MuiLink, Tooltip } from "@mui/material";
import Link from "../../common/link/link";
import { style } from "./button.style";

export const Button = ({
  type = "button",
  variant = "secondary",
  size = "default",
  children,
  onClick,
  ariaLabel,
  fullwidth,
  to,
  disabled = false,
  tooltipText = null,
  tooltipOpen = false,
  loading = false,
  ...rest
}) => {
  const defineClasses = () => {
    // let classes = "button";
    const classes = [style.customButton];
    let classNames = "button";

    switch (variant) {
      case "primary":
        classNames += " button_variant_primary";
        classes.push(style.customButton.primaryMod);
        break;
      case "secondary":
        classNames += " button_variant_secondary";
        classes.push(style.customButton.secondaryMod);
        break;
      case "outlined":
        classNames += " button_variant_outlined";
        classes.push(style.customButton.outlinedMod);
        break;
      default:
        break;
    }

    switch (size) {
      case "default":
        classNames += " button_size_default";
        classes.push(style.customButton.sizeMod.default);
        break;
      case "small":
        classNames += " button_size_small";
        classes.push(style.customButton.sizeMod.small);
        break;
      case "medium":
        classNames += " button_size_medium";
        classes.push(style.customButton.sizeMod.medium);
        break;
      case "modal":
        classNames += " button_size_modal";
        classes.push(style.customButton.sizeMod.modal);
        break;
      default:
        break;
    }

    if (fullwidth) classes.push(style.customButton.fullwidthMod);

    if (disabled) classes.push(style.disabled);

    return { classes, classNames };
  };

  return (
    <>
      {tooltipText ? (
        <Tooltip
          open={tooltipOpen}
          placement="top"
          title={<Box component="span" sx={style.tooltipText}>{tooltipText}</Box>}
          arrow
          sx={style.tooltip}
        >
          {type !== "link" ? (
            <ButtonBase
              type={type}
              className={defineClasses().classNames}
              sx={defineClasses().classes}
              onClick={onClick}
              aria-label={ariaLabel}
              {...rest}
            >
              {loading ? (<CircularProgress sx={style.progress} color="inherit" size={20} />) : null}
              {children}
            </ButtonBase>
          ) : (
            <MuiLink
              component={Link}
              to={to}
              className={defineClasses().classNames}
              sx={defineClasses().classes}
              onClick={onClick}
              aria-label={ariaLabel}
              {...rest}
            >
              {loading ? (<CircularProgress sx={style.progress} color="inherit" size={20} />) : null}
              {children}
            </MuiLink>
          )}
        </Tooltip>
      ) : (
        <>
          {type !== "link" ? (
            <ButtonBase
              type={type}
              className={defineClasses().classNames}
              sx={defineClasses().classes}
              onClick={onClick} aria-label={ariaLabel}
              {...rest}
            >
              {loading ? (<CircularProgress sx={style.progress} color="inherit" size={20} />) : null}
              {children}
            </ButtonBase>
          ) : (
            <MuiLink
              component={Link}
              to={to}
              className={defineClasses().classNames}
              sx={defineClasses().classes}
              onClick={onClick}
              aria-label={ariaLabel}
              {...rest}
            >
              {loading ? (<CircularProgress sx={style.progress} color="inherit" size={20} />) : null}
              {children}
            </MuiLink>
          )}
        </>
      )}
    </>
  );
};
