import { useSelector } from "react-redux";

import {
  getTranslationDefaultLocaleState, getTranslationLocales, getTranslationByLocaleState
} from "../store/selectors";

import { mergeDeep } from "../utils/helpers";
import { useLang } from "./use-lang";

export const useTranslation = () => {
  const lang = useLang();

  const defaultLocale = useSelector(getTranslationDefaultLocaleState);
  const translationLocales = useSelector(getTranslationLocales);

  const defaultTranslation = useSelector(getTranslationByLocaleState(defaultLocale));

  if (translationLocales.indexOf(lang) === -1) {
    return defaultTranslation;
  }

  const currentTranslation = useSelector(getTranslationByLocaleState(lang));

  return mergeDeep({}, defaultTranslation, currentTranslation);
};
