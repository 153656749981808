import { ROUTE } from "../utils/constants";

export const appRoutes = [
  {
    isSsr: true,
    path: `${ROUTE.APP.REDIRECT_PAYMENT_STATUS}/:status`,
    tag: "redirect-payment-status",
    component: () => import("../pages/app/redirect-payment-status/redirect-payment-status")
  },
  {
    isSsr: true,
    path: ROUTE.APP.HOME,
    tag: "home",
    component: () => import("../pages/app/home/home")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.HOME}`,
    tag: "home",
    component: () => import("../pages/app/home/home")
  },
  // {
  //   path: "/:lang/proxy/:proxyType/:social/",
  //   tag: "proxy-for-goal",
  //   component: (() => import("../pages/app/proxy/proxy"))
  // },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.MOBILE}`,
    tag: "mobile-proxy",
    component: () => import("../pages/app/mobile-proxy/mobile-proxy")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.IPV6}`,
    tag: "ipv6-proxy",
    component: () => import("../pages/app/proxy/proxy")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.ISP}`,
    tag: "isp-proxy",
    component: () => import("../pages/app/proxy/proxy")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.PAYMENT_SUCCESS}`,
    tag: "thanks",
    component: () => import("../pages/app/thanks/thanks")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.PAYMENT_REPLENISHMENT}`,
    tag: "balance-thanks",
    component: () => import("../pages/app/thanks/thanks")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.FAQ}`,
    tag: "faq",
    component: () => import("../pages/app/faq/faq")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.PROMO_CODES}`,
    tag: "promocodes",
    component: () => import("../pages/app/promocodes/promocodes")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.REVIEWS}`,
    tag: "reviews",
    component: () => import("../pages/app/reviews/reviews")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.BLOG}`,
    tag: "blog",
    component: () => import("../pages/app/blog/blog")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.BLOG}/:tag`,
    tag: "article",
    // ../pages/common/not-found/not-found
    component: () => import("../pages/app/article/article")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.CONTACT_US}`,
    tag: "contacts",
    component: () => import("../pages/app/contacts/contacts")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.CONDITIONS}`,
    tag: "conditions",
    component: () => import("../pages/app/conditions/conditions")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.OFFER}`,
    tag: "offer",
    component: () => import("../pages/app/offer/offer")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.PRIVACY_POLICY}`,
    tag: "policy",
    component: () => import("../pages/app/policy/policy")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.REFUND_POLICY}`,
    tag: "refund-policy",
    component: () => import("../pages/app/refund-policy/refund-policy")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.COOKIE_POLICY}`,
    tag: "cookie-policy",
    component: () => import("../pages/app/cookie-policy/cookie-policy")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.PRICES}`,
    tag: "price",
    component: () => import("../pages/app/price/price")
  },
  {
    isSsr: true,
    path: `/:lang${ROUTE.APP.NOT_FOUND}`,
    tag: "not-found",
    component: () => import("../pages/common/not-found/not-found")
  },
  {
    isSsr: true,
    path: "/:lang/:proxyCountry/",
    tag: "countries",
    component: () => import("../pages/app/country-proxy/country-proxy")
  }
];
