import { Box } from "@mui/material";
import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";

import { DownIcon } from "../../../components/icons/index";

import { useLang, useCollapse, useMatchedRoute, useTranslation } from "../../../hooks";
import DynamicIcon from "../dynamic-icon/dynamic-icon";
import { style } from "./sidebar.style";

export const SidebarItem = ({ translation, route, parentPage }) => {
  const lang = useLang();
  const { path } = useMatchedRoute();
  const { collapseRef, handleCollapse, isCollapseOpen } = useCollapse({
    defaultValue: true
  });
  const t = useTranslation();

  const routePath = `${parentPage}/${route.key}`;
  const currentPath = path.split("/").slice(2, path.split("/").length).join("/");

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (isCollapseOpen && !path.split("/")[4]) handleCollapse();
  //   }, 350);
  //
  //   return () => clearTimeout(timeout);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [path]);

  const defineClasses = useMemo(() => {
    let classes = "sidebar__item";

    if (routePath === currentPath) classes += " sidebar__item_active";
    if (route.sub?.some((item) => `${routePath}/${item.key}` === currentPath)) {
      classes += " sidebar__item_active";
    }

    if (isCollapseOpen) classes += " sidebar__item_open";

    return classes;
  }, [currentPath, isCollapseOpen, route.sub, routePath]);

  const defineStyles = useMemo(() => {
    const styles = [style.sidebarItem];
    if (routePath === currentPath) styles.push(style.sidebarItem.active);
    if (route.sub?.some((item) => `${routePath}/${item.key}` === currentPath)) {
      styles.push(style.sidebarItem.active);
    }

    if (isCollapseOpen) styles.push(style.sidebarItem.open);

    return styles;
  }, [currentPath, isCollapseOpen, route.sub, routePath]);

  return (
    <Box component="li" sx={defineStyles} key={route.key} className={defineClasses}>
      {!route.sub?.length ? (
        <Box component={RouterLink} to={`/${lang}/${routePath}/`}>
          {route.icon ? <Box component="span" sx={style.sidebarItem.icon} className="sidebar__item-icon">
            <DynamicIcon iconName={route.icon}/>
          </Box> : null}
          {t[translation][route.translation].label}
          {route.sub?.length && <DownIcon className="sidebar__item-arrow" />}
        </Box>
      ) : (
        <Box component="span" onClick={handleCollapse}>
          {route.icon ? <Box component="span" sx={style.sidebarItem.icon} className="sidebar__item-icon">
            <DynamicIcon iconName={route.icon}/>
          </Box> : null}
          {t[translation][route.translation].label}
          {route.sub?.length && <DownIcon className="sidebar__item-arrow" />}
        </Box>
      )}
      {route.sub?.length && (
        <Box sx={style.sidebarItem.sub} ref={collapseRef} className="sidebar__item-sub collapse">
          {route.sub?.length &&
            route.sub.map((item, idx) => (
              <Box
                component={RouterLink}
                key={`${idx}:${route.key}:${item}`}
                to={`/${lang}/${routePath}/${item.key}/`}
                sx={
                  currentPath !== `${routePath}/${item.key}`
                    ? [style.sidebarItem.sub.item]
                    : [style.sidebarItem.sub.item, style.sidebarItem.active]
                }
                className={
                  currentPath !== `${routePath}/${item.key}`
                    ? "sidebar__item-sub-item"
                    : "sidebar__item-sub-item sidebar__item-sub-item_active"
                }
              >
                {t[translation][route.translation].sub[item.translation]}
                {item?.extra ? ` ${item.extra}` : null}
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};
