import { ROUTE } from "../utils/constants";

export const toolsRoutes = [
  {
    path: `/:lang${ROUTE.TOOLS.MY_IP}`,
    tag: "my-ip",
    component: (() => import("../pages/tools/my-ip/my-ip"))
  },
  {
    path: `/:lang${ROUTE.TOOLS.PROXY_CHECKER}`,
    tag: "proxy-checker",
    component: (() => import("../pages/tools/proxy-checker/proxy-checker"))
  },
  {
    path: `/:lang${ROUTE.TOOLS.PORT_SCANNER}`,
    tag: "port-scanner",
    component: (() => import("../pages/tools/port-scanner/port-scanner"))
  },
  {
    path: `/:lang${ROUTE.TOOLS.PING_IP}`,
    tag: "ping-ip",
    component: (() => import("../pages/tools/ping-ip/ping-ip"))
  },
  {
    path: `/:lang${ROUTE.TOOLS.MY_ANONYMITY}`,
    tag: "my-anonymity",
    component: (() => import("../pages/tools/my-anonymity/my-anonymity"))
  },
  {
    path: `/:lang${ROUTE.TOOLS.FINGERPRINT_SCANNER}`,
    tag: "fingerprint-scanner",
    component: (() => import("../pages/tools/fingerprint-scanner/fingerprint-scanner"))
  },
  {
    path: `/:lang${ROUTE.TOOLS.CHECK_EVERY_COOKIE}`,
    tag: "check-evercookie",
    component: (() => import("../pages/tools/check-evercookie/check-evercookie"))
  },
  {
    path: `/:lang${ROUTE.TOOLS.CHECK_WEBRTC}`,
    tag: "check-webrtc",
    component: (() => import("../pages/tools/check-webrtc/check-webrtc"))
  },
  {
    path: `/:lang${ROUTE.TOOLS.IP_TRACING}`,
    tag: "ip-tracing",
    component: (() => import("../pages/tools/ip-tracing/ip-tracing"))
  }
];
