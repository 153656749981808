import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { PlusIcon } from "../../../../../components/icons/index";
import { Button } from "../../../../../components/ui/button/button";
import { Heading } from "../../../../../components/ui/heading/heading";
import { Modal } from "../../../../../components/ui/modal/modal";

import { useTranslation } from "../../../../../hooks/use-translations";
import { style } from "../tableOptions.style";
import { HeroInput } from "../../../../../components/heroForm/hero-input";
import { IpCommentService } from "../../../../../services/ipComment.service";

export const AddComment = ({ successFunc, ipId, editMode = false }) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const [inputValue, setInputValue] = useState("");

  // Translations
  const t = useTranslation();

  // handlers
  const handleOpen = () => {
    setIsOpen((prev) => !prev);
    if (!editMode) setInputValue("");
  };

  const handleChange = (value) => setInputValue(value);

  const handleSubmit = () => {

    const reqObj = {
      ipAddressId: ipId,
      comment: inputValue.trim()
    };

    IpCommentService.addComment(reqObj).then((res) => {
      if (res.data) {
        enqueueSnackbar(t.shared.table.options.addComment.success, { variant: "success" });
        successFunc(true);
        handleOpen();
      }
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e.message);
      enqueueSnackbar(t.shared.table.options.addComment.error, { variant: "error" });
    });
  };

  // get comment if exist
  useEffect(() => {
    if (editMode && isOpen) IpCommentService.getComment(ipId).then((res) => {
      if (res.data) setInputValue(res.data);
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e.message);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Box component="li" sx={style.item} className="table-options__item" onClick={handleOpen}>
        <PlusIcon />
        {!editMode ? t.shared.table.options.addComment.label : t.form.actions.edit}
      </Box>
      <Modal customStyles={style.modal} visible={isOpen} cancelHandler={handleOpen}>
        <Box sx={[style.comment, style.comment.addMod]} className="table-options__comment table-options__comment_add">
          <Heading tag="div" variant="h4">
            {!editMode ? t.shared.table.options.addComment.title : t.shared.table.options.addComment.editTitle}
          </Heading>
          <HeroInput
            rows={3}
            value={inputValue}
            onChange={handleChange}
            label={t.form.labels.commentToIp}
            isTextArea
            ref={inputRef}
          />
          <Box sx={style.comment.buttons} className="table-options__comment-buttons">
            <Button onClick={handleSubmit} disabled={!inputValue || inputValue.trim().length === 0} size="modal" variant="primary">
              {!editMode ? t.form.actions.add : t.form.actions.edit}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
