import { Helmet } from "react-helmet";

import useCurrentSeo from "../../../hooks/use-current-seo";
import { getRobotContent } from "../../../utils/helpers";

// TODO Filling SEO from one point. Functionality is repeated in server.ssr.js
const Metadate = ({ canonicalUrl }) => {
  const seo = useCurrentSeo();

  // console.count("Metadate");

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content="/images/favicons/mstile-144x144.png" />
      <meta itemProp="name" content={seo.title} />
      <meta itemProp="description" content={seo.description} />
      <meta itemProp="image" content="/images/favicons/mstile-144x144.png" />
      {/* fb */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content="/images/favicons/mstile-144x144.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="1200" />
      {/* twitter */}
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content="/images/favicons/mstile-144x144.png" />
      <meta name="robots" content={getRobotContent()} />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#517FF5" />
      <meta name="lava" content="6nfUDayi8eHu7J2wET9ClbQt3LcVk5WprG4YdsZFjho01zAP" />
      <meta name="interkassa-verification" content="16363c8a9821d848080875c98446ee81" />
      {/*Links*/}
      <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
      <link rel="manifest" href="/images/favicons/site.webmanifest" />
      <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color="#5bbad5" />
      <link
        rel="prefetch"
        href="/fonts/Gilroy-UltraLight.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/Gilroy-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/Gilroy-Medium.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/Gilroy-Semibold.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/Gilroy-Bold.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/SFPro-Medium.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/SFPro-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/FuturaNow-Bold.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/Gibson-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="prefetch"
        href="/fonts/NotoSans-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default Metadate;
