import { useEffect } from "react";
import { useActions, useLang } from "../../hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsAuthenticatedUser } from "../../store/user/user.selectors";
import { UserService } from "../../services";

const AuthorizationCheckWithRedirect = () => {
  const lang = useLang();
  const { token } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(getIsAuthenticatedUser);
  const { setUser } = useActions();


  useEffect(() => {
    if (pathname.includes("cabinet")) {
      if (!token) {
        UserService.authorizationCheck().then((res) => {
          if (!res.data) {
            setUser(null);
          }
        });
        if (!isAuthenticated) {
          // console.log(pathname);
          return navigate(`/${lang}/auth/login/`, { state: pathname });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isAuthenticated, lang]);
};

export default AuthorizationCheckWithRedirect;
