import { createSlice } from "@reduxjs/toolkit";
import {
  translationsEn,
  translationsRu,
  translationsUa
} from "../../utils/translations";

export const tranlsationState = {
  defaultLocale: "en",
  translations: {
    en: translationsEn,
    ru: translationsRu,
    uk: translationsUa
  },
};

const TranslationSlice = createSlice({
  name: "translation",
  initialState: tranlsationState,
  reducers: {}
});

export const TranslationActions = {
  ...TranslationSlice.actions
};

export default TranslationSlice.reducer;
